import React from 'react';
import { useAuth0 } from "@auth0/auth0-react";

function App() {
    const { loginWithRedirect, logout, isAuthenticated, user } = useAuth0();

  return (
      <div className="App">
        {!isAuthenticated ? (
            <button onClick={() => loginWithRedirect()}>Log In</button>
        ) : (
            <div>
                <button onClick={() => logout({ returnTo: window.location.origin })}>Log Out</button>
                <div>
                    {user && (
                        <div>
                            <p>Name: {user.name}</p>
                            <p>Email: {user.email}</p>
                            <img src={user.picture} alt={user.name} />
                        </div>
                    )}
                </div>
            </div>
        )}
      </div>
  );
}

export default App;